const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
  SCROLLABLE: "scrollable",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
}

const leftBarThemeImageTypes = {
  NONE: "none",
  IMG1: "img1",
  IMG2: "img2",
  IMG3: "img3",
  IMG4: "img4",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  COLORED: "colored",
  DARK: "dark",
  WINTER: "winter",
  LADYLIP: "ladylip",
  PLUMPLATE: "plumplate",
  STRONGBLISS: "strongbliss",
  GREATWHALE: "greatwhale",
}

const reportWorkTypes = [
  {"id": 'all', "title":"All"},
  {"id": 'new', "title":"New"},
  {"id": 'redo', "title":"Redo"},
  {"id": 'corr', "title":"Correction"}
]

const workType = [
  {"id": 'new', "title":"New"},
  {"id": 'redo', "title":"Redo"},
  {"id": 'corr', "title":"Correction"}
]

const dispath = [
  {"id": '0', "title":"Yes"},
  {"id": '1', "title":"No"}
]

const colors = {
  'silver': '#C0C0C0',
  'gold': '#D4AF37',
  'platinum': '#E5E4E2',
  'trail': '#af2626'
}

const deliveryModeOptions = [
  {value:1, label:'Courier'},
  {value:2, label:'Delivery Boy'},
  {value:3, label:'Doctors Pickup'},
  {value:4, label:'Mail'}
]

const DashboardReportTypes = [
  {value:"w", title:"Weekly"},
  {value:"m", title: "Monthly"},
  {value:"y", title: "Yearly"}
]

const GstStateCodes = [
  {"state": "Andhra Pradesh", "code": "37"},
  {"state": "Arunachal Pradesh", "code": "12"},
  {"state": "Assam", "code": "18"},
  {"state": "Bihar", "code": "10"},
  {"state": "Chhattisgarh", "code": "22"},
  {"state": "Goa", "code": "30"},
  {"state": "Gujarat", "code": "24"},
  {"state": "Haryana", "code": "06"},
  {"state": "Himachal Pradesh", "code": "02"},
  {"state": "Jharkhand", "code": "20"},
  {"state": "Karnataka", "code": "29"},
  {"state": "Kerala", "code": "32"},
  {"state": "Madhya Pradesh", "code": "23"},
  {"state": "Maharashtra", "code": "27"},
  {"state": "Manipur", "code": "14"},
  {"state": "Meghalaya", "code": "17"},
  {"state": "Mizoram", "code": "15"},
  {"state": "Nagaland", "code": "13"},
  {"state": "Odisha", "code": "21"},
  {"state": "Punjab", "code": "03"},
  {"state": "Rajasthan", "code": "08"},
  {"state": "Sikkim", "code": "11"},
  {"state": "Tamil Nadu", "code": "33"},
  {"state": "Telangana", "code": "36"},
  {"state": "Tripura", "code": "16"},
  {"state": "Uttar Pradesh", "code": "09"},
  {"state": "Uttarakhand", "code": "05"},
  {"state": "West Bengal", "code": "19"},
  {"state": "Delhi (National Capital Territory of Delhi)", "code": "07"},
  {"state": "Puducherry (Union Territory)", "code": "34"},
  {"state": "Chandigarh (Union Territory)", "code": "04"},
  {"state": "Dadra and Nagar Haveli and Daman and Diu (Union Territory)", "code": "26"},
  {"state": "Lakshadweep (Union Territory)", "code": "31"},
  {"state": "Andaman and Nicobar Islands (Union Territory)", "code": "35"},
  {"state": "Ladakh (Union Territory)", "code": "38"},
  {"state": "Jammu and Kashmir (Union Territory)", "code": "01"}
]

const months = [
  {value:1, label:"January"},
  {value:2, label:"February"},
  {value:3, label:"March"},
  {value:4, label:"April"},
  {value:5, label:"May"},
  {value:6, label:"June"},
  {value:7, label:"July"},
  {value:8, label:"August"},
  {value:9, label:"September"},
  {value:10, label:"October"},
  {value:11, label:"November"},
  {value:12, label:"December"}
];

let years = [
  {value:2024, label: '2024'},
  {value:2025, label: '2025'},
  {value:2026, label: '2026'},
  {value:2027, label: '2027'},
  {value:2028, label: '2028'},
  {value:2029, label: '2029'},
  {value:2030, label: '2030'},
  {value:2031, label: '2031'},
  {value:2032, label: '2032'},
  {value:2033, label: '2033'},
  {value:2034, label: '2034'},
  {value:2035, label: '2035'},
  {value:2036, label: '2036'},
  {value:2037, label: '2037'},
  {value:2038, label: '2038'},
  {value:2039, label: '2039'},
  {value:2040, label: '2040'},
];

const currentYear = new Date().getFullYear();
years = years.filter(year => year.value <= currentYear);

const sortDateFilters = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "last_7_days" },
  { label: "Last 15 days", value: "last_15_days" },
  { label: "This month", value: "this_month" },
  { label: "Last month", value: "last_month" },
  { label: "Last 45 days", value: "last_45_days" },
  { label: "Last 60 days", value: "last_60_days" }
];

export {
  DashboardReportTypes,
  GstStateCodes,
  colors,
  deliveryModeOptions,
  dispath,
  layoutTypes,
  layoutWidthTypes,
  leftBarThemeImageTypes,
  leftSideBarThemeTypes,
  leftSidebarTypes,
  reportWorkTypes,
  topBarThemeTypes,
  workType,
  months,
  years,
  sortDateFilters
}

