import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { get } from 'helpers/api_helper'
import { userModuleData, userRole } from 'helpers/basic_helper'
import { EMPLOYEE_URL } from "helpers/url_helper"
import { useDispatch, useSelector } from 'react-redux'
import { addEmployeeRecord, getEmployeeAllRows, removeEmployeeRecord } from 'store/actions'
import Form from "./component/Form"
import Datatable from "components/Datatables/datatable"
import { Link } from "react-router-dom";

const Employee = props => {
    const dispatch = useDispatch()
    const { loading, employees } = useSelector(state => state.Employee);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [DDMasters, setDDMasters] = useState([])
    const [isDelete, setIsDelete] = useState(false)
    const [pagination, setPagination] = useState({page: 1, sizePerPage: 10});
    
    const [isEdit, setIsEdit] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false);

    const getData = async() => {
        const role = await userRole();
        if(role == 'Superadmin'){
            const data = {
                edit: true,
                delete: true,
                add: true,
            }
        
            // setIsActions(data)
            if(data && (data?.edit || data?.delete)){
                // setIsActionColumn(true)
            }
        }else{
            const data =  await userModuleData('employee_master', 'employees')
            // setIsActions(data)
            if(data && (data?.edit || data?.delete)){
                // setIsActionColumn(true)
            }
        }

        const opts = await get('/api/employee/getOptions');
        if(opts.status === 200){
            setDDMasters(opts.response);
        }
        dispatch(getEmployeeAllRows({url:`${EMPLOYEE_URL}?limit=${pagination.sizePerPage}&page=${pagination.page}`, isSingle: false}, props.history))
    }

    useEffect(() => {
        setDataRows(employees)
    },[employees])    
    useEffect(() => getData(),[pagination])
    
    /**
     * Add or Update Data
     * @param {Row_id} data 
     */
    const submitForm = (data) => {
        const Obj = {
            // "image"  : data.target.image.value,
            "id"  : data.id,
            "firstname"  : data.firstname,
            "middlename"  : data.middlename, 
            "lastname"  : data.lastname,
            "gender"  : data.gender,
            "code"  : data.code,
            "mobile"  : data.mobile,
            "phone"  : data.phone,
            "dob"  : data.dob,
            "doj"  : data.doj,
            "email"  : data.email, 
            "role"  : data.role, 
            "shift"  : '', //data.shift, 
            "shiftgroup"  : '', //data.shiftgroup, 
            "designation"  : data.designation, 
            "department"  : data.department, 
            "lab_department"  : data.lab_department, 
            "location"  : '', //data.location, 
            "pan"  : data.pan, 
            "uid"  : data.uid, 
            "address1"  : data.address1, 
            "address2"  : data.address2,
            "branch"  : data.branch
        }

        dispatch(addEmployeeRecord({Obj:Obj, url: EMPLOYEE_URL+`${data?.id ? '?q='+data?.id: '' }`, id: data?.id}, props.history, () => {
            console.log('abc')
            isOpen(!open)
        }))
    }

    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = async(q) => {
        const opts = await get(`${EMPLOYEE_URL}?q=${q}`);
        if(opts.status == 200){
            setEditableObj(opts.response);
            isOpen(!open)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = (data, status) => {
        if(data > 0){
            setIsDelete(data)
            dispatch(removeEmployeeRecord({url:`${EMPLOYEE_URL}?q=${data}&status=${status}`, data: {id:data, status:status}}, () => {
                setIsDelete(false)
            }))
        }
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            dataField: 'id',
            text: 'Id'
        }, {
            dataField: 'firstname',
            text: 'Employee Name',
            formatter: (cell, row) => `${row.firstname} ${row.middlename} ${row.lastname}`
        },{
            dataField: 'code',
            text: 'Employee Code'
        },{
            dataField: 'branch',
            text: 'Branch'
        },{
            dataField: 'email',
            text: 'Email'
        },{
            dataField: 'mobile',
            text: 'Mobile'
        },{
            dataField: 'gender',
            text: 'Gender',
            formatter: (cell, row) => row.gender == 1 ? 'Male' : 'Female'
        },{
            dataField: 'designation',
            text: 'Designation'
        }, {
            dataField: 'actions',
            text: "",
            isDummyField: true,
            // formatExtraData: [ btnLoading.wpBtn, btnLoading.pdfBtn ],
            formatter: (cell, row) => <>
                <Link to="#" className="btn btn-outline-secondary btn-sm edit" onClick={() => handleEdit(row.id)} title="Edit" ><i className={`fas fa-pencil-alt`} /></Link>
                {" "}
                <Link to="#" className={`btn btn-${row.status == 0 ? "danger" : "success"} btn-sm edit`} onClick={() => handleRemove(row.id, row.status == 1 ? 0 : 1, row.code)} title={row.status == 0 ? 'delete' : 'restore'} ><i className={`fas fas fa-trash-alt`} /></Link>
            </>
        }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | Employees</title></MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={"Employee"} />
                    <Row>
                        <Col xl="12">
                            <Datatable
                                keyField={'id'}
                                title={`Countries`}
                                isAdd={true}
                                placeholder={'Search by name, code'}
                                isSearch={true}
                                handleAddButton={isOpen}
                                columns={datatableData}
                                rows={dataRows?.rows || []}
                                rowsLength={parseInt(dataRows?.total_rows) || 0}
                                loading={loading}
                                ssr={setPagination}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Datatable */}
            {
                open &&
                <Form
                    title={`${editableObj ? 'Edit' : 'Add'} Employee`}
                    Position={"right"}
                    isOpen={open}
                    width={60}
                    DDMasters={DDMasters}
                    length={dataRows?.length}
                    editableObj={editableObj}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(!open)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}
        </React.Fragment>
    )
}

export default Employee
