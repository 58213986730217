import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Col,
    Container,
    Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Notifications from "components/Notifications";
import DeleteConfirmationModel from "components/delete-confirmation-model";
import { getCode, userModuleData } from 'helpers/basic_helper';
import { PRODUCT_OPTIONS_URL, PRODUCT_URL } from "helpers/url_helper";
import { useDispatch, useSelector } from 'react-redux';
import { addRecord, getAllRows, productOptions } from 'store/actions';
import FormComponent from "../Masters/FormComponent";
import Datatable from "components/Datatables/datatable"
import { Link } from "react-router-dom";

const isType = 'Product'
const Product = props => {
    const dispatch = useDispatch()
    const { products, DDProduct } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(false)
    const [dataRows, setDataRows] = useState([])
    const [loading, Loading] = useState(false)
    const [DDCategories, setDDCategories] = useState(false)
    const [DDWarranty, setDDWarranty] = useState(false)
    const [DDTypes, setDDTypes] = useState(false)
    const [DDGroups, setDDGroups] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [pagination, setPagination] = useState({page: 1, sizePerPage: 10});
    
    const [isEdit, setIsEdit] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false);

    const getData = async() => {
        dispatch(getAllRows({url:`${PRODUCT_URL}?limit=${pagination.sizePerPage}&page=${pagination.page}`, isSingle: false, isType: isType}, props.history))
        dispatch(productOptions({url:PRODUCT_OPTIONS_URL}))
    }
    
    useEffect(() => {
        setDataRows(products.data)
    },[products])

    useEffect(() => getData(),[pagination])
    
    useEffect(() => {
        setDDCategories(DDProduct?.category?.map( v => ({label: v.title, value: v.code})))
        setDDGroups(DDProduct?.group?.map( v => ({label: v.title, value: v.code})))
        setDDWarranty(DDProduct?.warranty?.map( v => ({label: v.title, value: v.code})))
        setDDTypes(DDProduct?.producttype?.map( v => ({label: v.title, value: v.code})))
    } ,[DDProduct])
    
    // Open RightSide Panel
    const submitForm = (e, data) => {
        const checkCode = dataRows?.rows.filter(v => (v.code).toUpperCase() == (data.code).toUpperCase())
        if(data?.id =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        const addupdate_callback = () => {
            getData()
            isOpen(false)
            Notifications('You have successfully added a Product.!')
        }

        if(data.selectedValue){
            Object.keys(data.selectedValue)?.map( v => {
                data[v] = data.selectedValue[v].value
            })
        }

        const Obj = {
            "id": data.id ? data.id : '',
            "title": data.title,
            "code": data.code,
            "legancy_code":"",
            "desc":"",
            "group":data?.group || "",
            "brand": data?.brand || "",
            "warranty":data.warranty,
            "category":data.category,
            "type":data.type,
            "unit_price":data.unit_price
        }
        dispatch(addRecord({isType:isType, Obj:Obj, url: PRODUCT_URL+`${data?.target?.id?.value ? '?q='+data?.target?.id?.value : '' }`, id: data?.target?.id?.value}, props.history, addupdate_callback))
    }
    
    // Update Data , Get Singlw ROw using url params ?q=?
    const handleEdit = data => {
        const cb = (data) => {
            setEditableObj(data)
            Loading(false)
            isOpen(true)
        }
    
        if (data > 0) {
            setEditableObj({ id: data })
            Loading(true)
            dispatch(getAllRows({url:`${PRODUCT_URL}?q=${data}`, isSingle: true}, props.history, cb))
        }
    }

    // Remove Data
    const handleRemove = (q, status, code) => {
        if(q > 0){
            setIsDeleteModel({url: PRODUCT_URL, q, status, code, isMaster: false})
        }
    }

    const hideModel = () => {
        setIsDeleteModel(false)
        getData()
    }
    
    const loadProductTypes = () => {}

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            dataField: 'id',
            text: '#'
        }, {
            dataField: 'title',
            text: 'Product'
        },{
            dataField: 'code',
            text: 'Product Code',
            formatter: (cell, row ) => <b>{row.code}</b>
        },{
            dataField: 'unit_price',
            text: 'Product Price',
            formatter: (cell, row) => <b>Rs.{row.unit_price}</b> 
        },{
            dataField: 'acategory',
            text: 'Product Category'
        },{
            dataField: 'awarranty',
            text: 'Product Warranty'
        },{
            dataField: 'atype',
            text: 'Product Type'
        }, {
            dataField: 'actions',
            text: "",
            isDummyField: true,
            // formatExtraData: [ btnLoading.wpBtn, btnLoading.pdfBtn ],
            formatter: (cell, row) => <>
                <Link to="#" className="btn btn-outline-secondary btn-sm edit" onClick={() => handleEdit(row.id)} title="Edit" ><i className={`fas fa-pencil-alt`} /></Link>
                {" "}
                <Link to="#" className={`btn btn-${row.status == 0 ? "danger" : "success"} btn-sm edit`} onClick={() => handleRemove(row.id, row.status == 1 ? 0 : 1, row.code)} title={row.status == 0 ? 'delete' : 'restore'} ><i className={`fas fas fa-trash-alt`} /></Link>
            </>
        }
    ];

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "Product Name",
            placeholder: "Please Enter Product Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Product Code",
            placeholder: "Please Enter Product Code",
            required: true,
            type: 'text',
            value: editableObj?editableObj.code:getCode("products", dataRows?.length),
            col: 6
        },{
            name: "unit_price",
            label: "Product Unit Price",
            placeholder: "Please Enter Product Unit Price",
            required: true,
            type: 'text',
            value: editableObj?editableObj.unit_price : '',
            col:6
        },{
            name: "category",
            label: "Product Category",
            placeholder: "Please Select Product Category",
            required: true,
            type: 'select',
            isMulti: false,
            options: DDCategories,
            callback: loadProductTypes,
            value: editableObj? DDCategories?.filter( v => v.value == editableObj.category) : '',
            col:6
        // },{
        //     name: "group",
        //     label: "Product Group",
        //     placeholder: "Please Enter Product Group",
        //     required: false,
        //     type: 'select',
        //     isMulti: false,
        //     options: DDGroups,
        //     value: editableObj? DDGroups?.filter( v => v.value == editableObj.group) : '',
        //     col:6
        // },{
        //     name: "brand",
        //     label: "Product Brand",
        //     placeholder: "Please Select Product Brand",
        //     required: false,
        //     type: 'select',
        //     options: DDProduct?.brand?.map( v => ({label: v.title, value: v.code})),
        //     value: editableObj? DDProduct?.brand?.filter( v => v.code = editableObj.brand).map( v => ({label: v.title, value: v.id})) : '',
        //     col:6
        },{
            name: "warranty",
            label: "Product Warranty",
            placeholder: "Please Select Product Warranty",
            required: false,
            isMulti: false,
            type: 'select',
            options: DDWarranty,
            value: editableObj? DDWarranty?.filter( v => v.value == editableObj.warranty) : '',
            col:6
        },{
            name: "type",
            label: "Product Type",
            placeholder: "Please Select Product Type",
            required: false,
            isMulti: false,
            type: 'select',
            options: DDTypes,
            value: editableObj? DDTypes?.filter( v => v.value == editableObj.type) : '',
            col:6
        }
        /* ,{
            name: "legancy_code",
            label: "Legancy Code",
            placeholder: "Please Enter Legancy Code",
            required: false,
            type: 'text',
            value: editableObj?editableObj.legancy_code : '',
            col:6
        },{
            name: "desc",
            label: "Description",
            placeholder: "Please Enter Description",
            required: true,
            type: 'text',
            value: editableObj?editableObj.type : '',
            col:6
        } */
            // },{
        //     name: "title",
        //     label: "Offer",
        //     placeholder: "Please Enter Offer Code",
        //     required: false,
        //     type: 'text',
        //     value: editableObj?editableObj.offer : '',
        //     col:6
        // }
    ]

    
    useEffect(async() => {
        const data =  await userModuleData('product_master', 'products')
        setIsActions(data)
        if(data && (data?.edit || data?.delete)){
            setIsActionColumn(true)
        }
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={"Products"} />
                    <Row>
                        <Col xl="12">
                            <Datatable
                                keyField={'id'}
                                title={`Countries`}
                                isAdd={true}
                                placeholder={'Search by name, code'}
                                isSearch={true}
                                handleAddButton={isOpen}
                                columns={datatableData}
                                rows={dataRows?.rows || []}
                                rowsLength={parseInt(dataRows?.total_rows) || 0}
                                loading={loading}
                                ssr={setPagination}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* End Datatable */}
            {
                open &&
                <FormComponent
                    title={`${editableObj ? 'Edit' : 'Add'} Product`}
                    Position={"right"}
                    isOpen={open}
                    fileds={htmlFormFields}
                    loading={loading}
                    editableObj={editableObj}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(!open)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }
        </React.Fragment>
    )
}

export default Product
