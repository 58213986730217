import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Container,
    Modal,
    Row,
    Card,
    CardBody
} from "reactstrap";
import Select from 'react-select';
import Breadcrumbs from "components/Common/Breadcrumb";
import { userModuleData } from 'helpers/basic_helper';
import Notifications from "components/Notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getInvoices } from "store/actions";
import { months, years } from 'constants/layout'
import { clientOptions } from "store/actions";
import { Link } from "react-router-dom";
import { post, get } from 'helpers/api_helper';
import { customRound, formatReferenceNumber } from '../../utils/number_formatters'
import { CONVERTINVOICE_URL, INVOICE_CANCEL_URL, INVOICE_URL, SEND_URL, WHATSAPP_TEST_URL } from 'helpers/url_helper';
import moment from "moment";
import PrintModel from "./print";
import Datatable from "components/Datatables/datatable";
import { toNumber } from "lodash";

const isType = 'invoice'
const inputObj = {
    code: null,
    remark: ""
}

const confirmmationData = {
    date: new Date(),
    remark: "",
    bill_to: {}
}

const Invoices = (props) => {
    const dispatch = useDispatch()
    const invoiceType = window?.location?.pathname.includes('proforma') ? 'Proforma' : '';

    const { DDOptions } = useSelector(state => state.Master);
    const { loading, invoices } = useSelector(state => state.Invoices);
    const [dataRows, setDataRows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [printModal, setPrintModal] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationInput, setConfirmationInput] = useState(inputObj);
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false)
    const [confirmConvertModal, setConfirmConvertModal] = useState(null)
    const [confirmConvertData, setConfirmConvertData] = useState(confirmmationData)
    const [isOrdersList, setIsOrdersList] = useState(null)
    const [cancelInvoiceData, setCancelInvoiceData] = useState(null)
    const [convertingOrders, setConvertingOrders] = useState({lock:[], revert:[]})
    const [filters, setFilters] = useState({
        client: '',
        month: moment().format('M'),
        year: moment().format('YYYY'),
        invoice: '',
        page: 1,
        limit: 10,
        monthOpt: {value: moment().format('M'), label: moment().format('MMMM')},
        yearOpt: {value: moment().format('YYYY'), label: moment().format('YYYY')},
        type: invoiceType ? 'Proforma' : '',
    });
    const [ bulkPrint, setBulkPrint ] = useState({
        start: 0,
        end: 0
    });

    const [btnLoading, setBtnLoading] = useState({
        canBtn: null,
        wpBtn: null,
        pdfBtn: null,
        invBtn: null,
    });
    
    const getData = () => {
        const finalFilters = filters;
        delete finalFilters.monthOpt
        delete finalFilters.yearOpt

        const filteredParams = Object.fromEntries(
            Object.entries(finalFilters).filter(([_, v]) => v != null && v !== "")
        );
        const queryString = new URLSearchParams(filteredParams).toString();
        dispatch(getInvoices({url:`${INVOICE_URL}?${queryString}`}))
    }
    
    useEffect(() => {
        dispatch(clientOptions({type: 'billto'}))
    },[])

    useEffect(async() => {
        const data =  await userModuleData('invoice', 'invoices')
        setIsActions(data)
        if(data){
            data.add = false
            data.pdf = true
            data.sendwp = true
            data.delete = false
            data.edit = false
            data.lock = true
            setIsActionColumn(true)
        }
    } ,[invoiceType])

    useEffect(() => {
        if(Object.keys(invoices).length){
            setDataRows(invoices)
        }
    }, [invoices])

    const handleCancelInvoice = async(data, sub=false) => {
        if(sub){
            const res = await post(`${INVOICE_CANCEL_URL}?type=tax`, {invoice_no: data.invoice_number, remark: data.remark, proforma: data.proforma })
            if(res.status == 200){
                console.log(res);
                setCancelInvoiceData(null)
                setCancelInvoiceModal(false);
                getData()
                Notifications(`Invoice Cancelled.!`, 'error')
            }else{
                Notifications(`Please, try again.!`, 'error')
            }
        }else{
            setCancelInvoiceData(data)
            setCancelInvoiceModal(true);
        }
    }

    const getTotalValue = (total) => {
        const invoiceTotal = parseFloat(total);
        const gstAmount = invoiceTotal * 0.05;
        const totalWithGST = customRound(invoiceTotal + gstAmount);
        return totalWithGST.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
        });
    }

    // table Column Data
    let datatableData = [
        {
            dataField: 'invoice_number',
            text:  `${invoiceType} Invoice`,
            formatter: (cell, row) =>  {
                return  invoiceType == 'Proforma' ? row.invoice_number: formatReferenceNumber(row.invoice_number)
            }
        },{
            dataField: 'clientname',
            text:  'Client / Doctor'
        },{
            dataField: 'invoice_date',
            text:  'Invoice Date (DDMMYYYYY)'
        },{
            dataField: 'invoice_total',
            formatter: (cell, row) =>  getTotalValue(row.invoice_total),
            text:  'Total Amount ( ₹ )'
        },{
            dataField: 'invoice_status',
            formatter: (cell, row) =>  row.invoice_status == 1? <b className="text-danger">{"Cancelled"}</b>: '---',
            text:  'Invoice Status'
        }, {
            dataField: 'actions3',
            text: "",
            isDummyField: true,
            formatExtraData: [ btnLoading.wpBtn, btnLoading.pdfBtn ],
            formatter: (cell, row) => 

                row?.invoice_status == 1 ?
                <Link
                    target="_blank"
                    to={`/invoice/print?invoice_number=${row.invoice_number}`}
                    title={"View to Tax invoice"}
                    className={`btn btn-outline-danger ${row.status == 1 ? " disabled": ''} btn-sm`}>
                    <i className={`${(row.invoice_number == btnLoading.pdfBtn) ? 'fa fa-spinner fa-spin':'fas fa-file-pdf'}`} />
                </Link> :
                <>
                    {
                        (invoiceType != 'Proforma' && row.is_lock) ? 
                            <Link
                                target="_blank"
                                to={`/invoice/print?invoice_number=${row.invoice_number}`}
                                title={"View to Tax invoice"}
                                className={`btn btn-outline-danger ${row.status == 1 ? " disabled": ''} btn-sm`}>
                                <i className={`${(row.invoice_number == btnLoading.pdfBtn) ? 'fa fa-spinner fa-spin':'fas fa-file-pdf'}`} />
                            </Link>:
                            <Link
                                target="_blank"
                                to={`/invoice/print?invoice_number=${row.invoice_number}&type=proforma`}
                                title={"View to proforma invoice"}
                                className={`btn btn-outline-danger btn-sm`}>
                                <i className={`${(row.invoice_number == btnLoading.pdfBtn) ? 'fa fa-spinner fa-spin':'fas fa-file-pdf'}`} />
                            </Link>
                    }
                    {' '}
                    {
                        row.status == 0 &&
                        <Link
                            to="#"
                            title={"Send Invoice to Client"}
                            className={`btn btn-outline-success btn-sm`}
                            onClick={() =>{
                                setBtnLoading( p => ({...p, wpBtn: row.invoice_number}))
                                wpinvoice(row, 'proforma')
                            }} >
                            <i className={`${(row.invoice_number == btnLoading.wpBtn) ? 'fa fa-spinner fa-spin':'fab fa-whatsapp fa-1x'}`} />
                        </Link>
                    }
                    {' '}
                    {
                        (invoiceType != 'Proforma' && row.is_lock) ? 
                        <Link
                            to={'#'}
                            onClick={() =>handleCancelInvoice(row)}
                            title={"Cancel Tax invoice"}
                            className={`btn btn-outline-danger btn-sm`}>
                            <i className={`${(row.invoice_number == btnLoading.pdfBtn) ? 'fa fa-spinner fa-spin':'fa fa-close'}`} />
                        </Link>:''
                    }
                    {' '}
                    {
                         row.status == 0 &&(
                             (row?.is_lock == 0) ? (
                                 <>
                                     <Link
                                         to="#"
                                         title={"Convert to invoice"}
                                         className={`btn btn-outline-danger ${row.status == 1 ? " disabled": ''} btn-sm`}
                                         onClick={() =>{
                                             setConfirmConvertModal(row)
                                         }} >
                                         <i className={`${(row.invoice_number == btnLoading.pdfBtn) ? 'fa fa-spinner fa-spin':'fas fa-arrow-right'}`} />
                                     </Link>
                                     {" "}
                                 </>
                             ): (
                                 row?.is_lock && invoiceType == 'Proforma' &&
                                 <>
                                     {" "}
                                     <Link
                                         target="_blank"
                                         to={`/invoice/print?invoice_number=${row?.final_invoice}`}
                                         title={"View invoice"}
                                         className={`btn btn-outline-success btn-sm`} >
                                         <i className={`fas fa-eye`} />
                                     </Link>
                                 </>
                             )
                         )
                    }
                    {" "}
                    {
                        row.status == 0 && row?.is_lock == 0 &&
                        <Link
                            to={`#`}
                            title={"Cancel Proforma"}
                            onClick={() =>{
                                setOpenConfirmationModal(row.invoice_number)
                                setBtnLoading( p => ({...p, canBtn: row.invoice_number}))
                            }} 
                            className={`btn btn-danger btn-sm`} >
                            <i className={`fas fa-times`} />
                        </Link>
                    }
                </>
                
                // isProforma: invoiceType,
                // isInvoice: true,
                // cancelinvoice: (IN) => setOpenConfirmationModal(IN),
                // convertInvoice: (INO) => setConfirmConvertModal(INO),
                // sendMsg: (data) => wpinvoice(data, 'proforma')
            
        }
    ];

    if(invoiceType !== 'Proforma'){
        datatableData.splice(2, 0,{text:  'Proforma Invoice', dataField: 'proforma'})
    }

    const closeModel = (data) => {
        setIsOpen(data)
        setDataRows([])
    }

    const cancelinvoiceConfirm = async () => {
        const res = await post(INVOICE_CANCEL_URL, confirmationInput)
        if(res.status == 200){
            Notifications(`Invoice Cancelled.!`, 'error')
            getData()
            setConfirmationInput(inputObj)
            setOpenConfirmationModal(false)
        }else{
            Notifications(`Please, try again.!`, 'error')
        }
    }
    
    const ConvertInvoice = async(INO) => {
        const Obj = {
            "proforma_invoice_number": INO?.invoice_number,
            "invoice_date": confirmConvertData?.date,
            "remark": confirmConvertData?.remark,
            "bill_to": confirmConvertData?.bill_to?.value,
            'orders': JSON.stringify(convertingOrders)
        }
        const res = await post(`${CONVERTINVOICE_URL}`, Obj);
        
        if(res?.status === 200){
            setConvertingOrders({lock: [], revert: []})
            setConfirmConvertData(confirmConvertData)
            setConfirmConvertModal(false)
            window.open(`/invoice/print?invoice_number=${res?.response?.invoice_no}`, '_blank');
            getData(invoiceType);
        }
    }

    const wpinvoice = async(data, type) => {
        const res = await post(`${SEND_URL}`, {...data, type});
        if(res?.status === 200){
            Notifications(`Invoice Send to Whatsapp.!`,'success')
        }else{
            Notifications(`Customer hasn't registered for WhatsApp or the WhatsApp number doesn't exist.`, 'error')
        }
        setBtnLoading(p=> ({...p, wpBtn: null}))
    }

    useEffect(() => {
        getOrders()
    }, [confirmConvertModal])

    const getOrders = async() => {
        if(confirmConvertModal){
            const res = await get(`/api/invoice/piorders?_invoice=${confirmConvertModal.invoice_number}`)
            if(res?.status == 200) {
                const data = res?.response[0]?.order_no.split(', ');
                setIsOrdersList(data);
                setConfirmConvertData(prev => ({...prev, bill_to: DDOptions?.clients?.filter(v => v.value == confirmConvertModal?.client_id)[0]}))
                setConvertingOrders({ lock: data, revert: [] })
            }
        }
    }

    const handleCheckbox = (e) => {
        const input = e.target;
        if (input.dataset.type === 'lock') {
            setConvertingOrders(prev => ({
                lock: [...prev.lock, input.value],
                revert: prev.revert.filter(item => item !== input.value)
            }));
        } else {
            setConvertingOrders(prev => ({
                lock: prev.lock.filter(item => item !== input.value),
                revert: [...prev.revert, input.value]
            }));
        }
    };
    
    const filterOrderHandler =async(data)=>{
        setFilters(p => ({...p, page: data?.page || 1, limit: data?.sizePerPage || 10, searchTerm: data?.searchTerm}))
    }

    useEffect(() => {
        getData()
    }, [filters])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <label>Search by Order or Modal Number</label>
                                    <input className="form-control" placeholder="Please Search using Invoice Number" onChange={(e) => setFilters(p => ({...p, invoice: e.target.value}))} />
                                </Col>
                                <Col md={3}>
                                    <label>Select Client or Doctor</label>
                                    <Select
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={(e) => {
                                            setFilters(p => ({...p, client: e?.value, clientOPT: e}))
                                        }}
                                        isClearable={true}
                                        value={filters?.clientOPT}
                                        options={DDOptions?.clients}
                                        name={'client'}
                                        required={false}
                                        placeholder={'Select Client'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label>Year</label>
                                    <Select
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={(e) => {
                                            setFilters(p => ({...p, year: e?.value, yearOpt: e}))
                                        }}
                                        isClearable={true}
                                        value={filters?.yearOpt}
                                        options={years}
                                        name={'year'}
                                        required={false}
                                        placeholder={'Select Year'}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label>By Month</label>
                                    <Select
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={(e) => {
                                            setFilters(p => ({...p, month: e?.value, monthOpt: e}))
                                        }}
                                        isClearable={true}
                                        value={filters?.monthOpt}
                                        options={months}
                                        name={'month'}
                                        required={false}
                                        placeholder={'Select Month'}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                
                    {
                        dataRows &&
                        <Datatable
                            keyField={'invoice_number'}
                            title={`All ${invoiceType} Invoices`}
                            isAdd={true}
                            isSearch={true}
                            fas="fa-print"
                            btnTitle="Bulk Print"
                            columns={datatableData}
                            handleAddButton={() => setPrintModal(!printModal)}
                            rows={dataRows?.rows || []}
                            rowsLength={dataRows?.total_rows || 0}
                            loading={loading}
                            ssr={filterOrderHandler}
                        />
                    }

                    { dataRows && <PrintModel isOpen={isOpen} closeModel={closeModel}/> }
                </Container>
            </div>

            {
                printModal && 
                <Modal
                    size="md"
                    isOpen={printModal}
                    toggle={() => setPrintModal(!printModal)}
                    centered={true}
                >
                    <div className="modal-body">
                        <p>Please Enter Invoice Number Range.</p>
                        <label>Starting Invoice Number *</label>
                        <input type="number" name="start" onChange={(e) => setBulkPrint(prev => ({...prev, start: e.target.value}))} className="form-control" /> <br />
                        <label>Ending Invoice Number *</label>
                        <input type="number" name="end" onChange={(e) => setBulkPrint(prev => ({...prev, end: e.target.value}))} className="form-control" />
                    </div>

                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                setBulkPrint({start: 0, end: 0})
                                setPrintModal(false)
                            }} >Close</button>
                        <button 
                            disabled={(bulkPrint.start == 0 || bulkPrint.end == 0) ? true: false}
                            to="#"
                            className={`btn btn-danger`}
                            onClick={() => window.open(`${process.env.REACT_APP_BASEURL}bulk/${invoiceType == 'Proforma' ? 'proforma': 'tax'}/invoices?_from=${bulkPrint?.start}&_to=${bulkPrint?.end}`, '_blank', 'noopener, noreferrer')} >Print</button>
                    </div>
                </Modal>
            }
            
            {
                cancelInvoiceModal && 
                <Modal
                    size="md"
                    isOpen={cancelInvoiceModal}
                    toggle={() => setCancelInvoiceModal(!cancelInvoiceModal)}
                    centered={true}
                >
                    <div className="modal-body">
                        <p>Please Review Invoice.</p>
                        <label>Invoice #. {formatReferenceNumber(cancelInvoiceData?.invoice_number || 0)}</label><br />
                        <label>Client. {cancelInvoiceData?.clientname}</label><br />
                        <label>Proforma #. {cancelInvoiceData?.proforma}</label><br />
                        <label>Invoice Total #. {getTotalValue(cancelInvoiceData?.invoice_total)}</label><br />
                        
                        <label>Remark *</label>
                        <input type="text" name="remark" onChange={(e) => setCancelInvoiceData(prev => ({...prev, remark: e.target.value}))} className="form-control" />
                    </div>

                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                setCancelInvoiceData(null)
                                setCancelInvoiceModal(false)
                            }} >Close</button>
                        <button 
                            disabled={(cancelInvoiceData?.remark === undefined) ? true: false}
                            to="#"
                            className={`btn btn-danger`}
                            onClick={() => handleCancelInvoice(cancelInvoiceData, true)} >Submit</button>
                    </div>
                </Modal>
            }

            
            {
                openConfirmationModal && 
                <Modal
                    size="md"
                    isOpen={openConfirmationModal !== false}
                    toggle={() => setOpenConfirmationModal(false)}
                    centered={true}
                >
                    <div className="modal-body">
                        <p>Please Enter Invoice Number: <b><u>{openConfirmationModal}</u></b> for Cancellation.</p>
                        <label>Invoice Number*</label>
                        <input name="date" onChange={(e) => setConfirmationInput(prev => ({...prev, code: e.target.value}))} className="form-control" /> <br />
                        <label>Remark*</label>
                        <input name="remark" onChange={(e) => setConfirmationInput(prev => ({...prev, remark: e.target.value}))} className="form-control" />
                    </div>
                    <span className="text-danger ms-5 mb-2 fw-bold" style={{fontSize: '14px'}}>Once the invoice is canceled, it cannot be reverted back.</span>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setOpenConfirmationModal(false)} >Close</button>
                        <button 
                            type="button"
                            disabled={ (openConfirmationModal == confirmationInput.code && confirmationInput.remark.length > 5) ? false : true }
                            className={`btn btn-danger`}
                            onClick={cancelinvoiceConfirm} >Delete</button>
                    </div>
                </Modal>
            }

            {
                confirmConvertModal && 
                <Modal
                    size="md"
                    isOpen={confirmConvertModal !== false}
                    toggle={() => setConfirmConvertModal(false)}
                    centered={true}
                >
                    <div className="modal-body">
                        {/* <p>Please Enter Invoice Number: <b><u>{confirmConvertData}</u></b> for Convert to Invoice.</p> */}
                        <div className="row">
                            <span className="text-danger mb-2 fw-bold" style={{fontSize: '14px'}}>Once the invoice is Locked, it cannot be reverted back.</span>
                            <div className='col-6'>
                                <label>Invoice Date*</label>
                                <DatePicker
                                    className="form-control"
                                    minDate={new Date(confirmConvertModal?.invoice_date?.split('-').reverse().join('-'))}
                                    // maxDate={new Date(confirmConvertModal?.invoice_due_date.split('-').reverse().join('-'))}
                                    selected={new Date()}
                                    onChange={(date) => setConfirmConvertData(prev => ({...prev, date: date}))} />
                            </div>
                            <div className='col-6'>
                                <label>Remark*</label>
                                <input name="remark" onChange={(e) => setConfirmConvertData(prev => ({...prev, remark: e.target.value}))} className="form-control" />
                            </div>
                            <div className='col-12'>
                                <br />
                                <label>Bill To*</label>
                                {
                                    Object.keys(confirmConvertData?.bill_to).length > 0 ?
                                    <Select
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={(e) => {
                                            setConfirmConvertData(prev => ({...prev, bill_to: e}))
                                        }}
                                        isClearable={true}
                                        value={confirmConvertData.bill_to}
                                        options={DDOptions?.clients}
                                        name={'client'}
                                        required={false}
                                        placeholder={'Select Client'}
                                    />: ''
                                }
                            </div>
                        </div>
                        <br />

                        <div className="row">
                           
                            {
                                isOrdersList ? 
                                <div style={{ maxHeight: '480px', overflowX: 'auto' }}>
                                    <table className="table table-borderd">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Order No</th>
                                                <th>Lock</th>
                                                <th>Cancel</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ overflowY: 'auto' }}>
                                            {isOrdersList?.map((v, i) => {
                                                return <tr key={`OLPI_${i}`}>
                                                    <td>{i+1}</td>
                                                    <td>{v}</td>
                                                    <td><input type="radio" value={v.trim()} name={`sta${v}`} defaultChecked={true} data-type="lock" onClick={handleCheckbox} /></td>
                                                    <td><input type="radio" value={v.trim()} name={`sta${v}`} data-type="cancel" onClick={handleCheckbox} /></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                : <div className="text-center p-3">
                                    <br />
                                    <div style={{margin: '0 auto'}}>
                                        <div className="loader" style={{ position: 'absolute', margin: '-2.5% 42.5%'}}></div>
                                        <img src="https://dglab.keepsmiles.in/api/uploads/241002/241002_print.png" style={{ width: '22px', left: '26px', top: '30px' }}/>
                                    </div>
                                    <br />
                                    <b>Please Wait...</b>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setConfirmConvertModal(false)} >Close</button>
                        <button 
                            type="button"
                            className={`btn btn-danger`}
                            onClick={() => ConvertInvoice(confirmConvertModal)} >Lock Invoice</button>
                    </div>
                </Modal>
            }

            {/* {OrderEditModule('202409001897')} */}
        </React.Fragment>
    )
}

export default Invoices